<template>
    <div class="contenedor-form-general">
        <div class="logo">
            Allergan Aesthetics<br>
            <span style="font-weight: normal; font-size: 0.45em;">an AbbVie company</span>
        </div>
        <div class="contenedor-form">
            <p class="font-bold titulo">Crear cuenta</p>
            <form @submit.prevent="handleRegister">
                <div class="correo">
                    <span>Tu nombre</span>
                    <input v-model="nombre" type="text" placeholder="Nombre y apellido">
                </div>
                <div class="correo">
                    <span>Dirección de email</span>
                    <input v-model="email" type="email" placeholder="ejemplo@mail.com">
                </div>
                <div class="correo">
                    <span>Contraseña</span>
                    <input v-model="password" type="password" placeholder="Debe tener al menos 7 caracteres">
                </div>
                <div class="correo">
                    <span>Confirmar contraseña</span>
                    <input v-model="confirmPassword" type="password">
                </div>
                <!-- <div class="correo">
                    <span>Clave autorización</span>
                    <input v-model="clave" type="text" :disabled="isDisabled" @input="toUpper">

                </div> -->
                <button type="submit">Continuar</button>
            </form>
            <p class="terminos">Al crear una cuenta aceptas las <a href="/condiciones-de-uso">Condiciones de Uso</a> y el <a href="/aviso-de-privacidad">Aviso de privacidad</a>.</p>
        </div>
        <div class="caja-blanca">
            <p class="font-bold titulo">¿Ya tienes una cuenta?</p>
            <button @click="goToLogin">Iniciar sesión</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        claveProp: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            email: '',
            password: '',
            nombre: '',
            confirmPassword: '',
            // clave: '',
        };
    },
    computed: {
        isDisabled() {
            return !!this.claveProp;  // Retorna true si claveProp no es vacío, false de lo contrario
        }
    },
    methods: {
        handleRegister() {
            if (!this.validateEmail(this.email)) {
                alert("Por favor, introduce un correo electrónico válido.");
                return;
            }
            if (this.password !== this.confirmPassword) {
                alert("Las contraseñas no coinciden.");
                return;
            }
            axios.post('/api/register', {
                nombre: this.nombre,
                // clave: this.clave,
                email: this.email,
                password: this.password,
            }).then(response => {
                if (response.data.success) {
                    alert('Registro exitoso, ya puedes iniciar sesión');
                    this.goToLogin();
                } else {
                    alert(response.data.error);
                }
            }).catch(error => {
                console.error('Error de registro:', error);
                alert('Hubo un problema con tu solicitud de registro.');
            });
        },
        validateEmail(email) {
            const re = /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        goToLogin() {
            this.$router.push('/');
        },
        toUpper() {
            this.clave = this.clave.toUpperCase();
        }
    },
    mounted() {
        console.log("Prop value:", this.claveProp); // Esto ayudará a verificar si el valor se pasa correctamente.
        if (this.claveProp) {
            this.clave = this.claveProp.toUpperCase();
        }
    }
}
</script>


<style lang="sass" scoped >
.contenedor-form-general
    width: 100%
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center
    height: 100%
    .contenedor-form, .caja-blanca
        padding: 20px 15px 15px 15px
        background: #fff
        // border-radius: 20px
        width: 100%
        max-width: 529px
        margin-top: 20px
        .titulo
            font-size: 32px
            text-align: center
            margin-bottom: 20px
        form 
            position: relative
            text-align: left
            .correo, .password
                span
                    display: block
                    width: 100%
                    margin: 0
                    padding: 0
                    font-size: 20px
                    font-weight: bold
                    margin: 25px 0 0 0
                input
                    width: 100%
                    border: none
                    padding: 10px 15px
                    // border-radius: 5px
                    background: #fff
                    border-bottom: 2px solid #000
                    transition: all 0.2s
                    margin-bottom: 20px
                    &:focus, &:active                                                
                      outline: none
                      border: none!important
                      border-bottom: 2px solid #999!important
            button
                background: #000
                color: #fff
                margin-top: 15px
                font-size: 0.8em
                text-align: center
            .password
                margin-top: 15px
        button
            display: block
            width: 100%
            border: none            
            padding: 15px
            text-align: left            
            cursor: pointer
            font-size: 16px
        .terminos, .pass-olvidado
            font-size: 0.7em
            max-width: 100%
            margin: 20px auto
            a
                color: #555
                text-decoration: none   
                cursor: pointer
                transition: all 0.2s
                font-weight: bold
                text-decoration: underline
                &:hover
                  color: #000
        .pass-olvidado
            display: flex
            align-items: center
            svg
                transform: scale(0.9)
                margin-left: 5px        
    .caja-blanca
        button
            background: #000
            color: #fff
            text-align: center
/* Animaciones */
// .slide-fade-enter-active, .slide-fade-leave-active
//     transition: all 0.2s ease

// .slide-fade-enter, .slide-fade-leave-to /* Inicio y fin de estado para enter/leave */
//     transform: translateY(10px)
//     opacity: 0

.fade-enter-active, .fade-leave-active
    transition: opacity 0.2s ease
.fade-enter, .fade-leave-to
    opacity: 0
.move-up-enter-active, .move-up-leave-active
    transition: transform 0.2s ease, opacity 0.2s ease
.move-up-enter, .move-up-leave-to
    transform: translateY(-20px)
    opacity: 0
.slide-fade-enter-active, .slide-fade-leave-active
    transition: all 0.2s ease
.slide-fade-enter, .slide-fade-leave-to
    transform: translateY(10px)
    opacity: 0
.email-placeholder
    cursor: pointer
    position: absolute
    line-height: 0
    opacity: 0
    // top: 0
    // display: none
    height: 0
    transition: all 0.2s
.email-placeholder.active
    // display: block
    position: relative
    height: auto
    line-height: 1
    opacity: 1
.password
    opacity: 0
.password.pass-active
    opacity: 1
.logo 
  width: fit-content
  margin: 20px
  padding: 10px
  font-size: 24px 
  font-family: Arial, Helvetica, sans-serif
  font-weight: bold
  text-align: right
  line-height: 0.6
</style>