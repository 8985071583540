<template>
  <div class="contenedor-form-general">
      <div class="logo">

        Allergan Aesthetics<br>
        <span style="font-weight: normal; font-size: 0.45em;">an AbbVie company</span>


      </div>
      <div class="contenedor-form">
          <p class=" font-bold titulo">Iniciar sesión</p>
          <form @submit.prevent="handleFormSubmit">
              <transition name="fade" @after-leave="afterEmailFade">
                  <div v-if="!emailConfirmed && !animating" class="correo" key="correo-input">
                      <span class="">Dirección de email</span>                      
                      <input v-model="email" type="email" placeholder="ejemplo@mail.com" @input="resetAnimation" @keyup.enter="startEmailAnimation">
                      <button type="button" @click="startEmailAnimation">Continuar</button>
                  </div>
              </transition>
              <transition name="move-up" @after-enter="afterEmailMoveUp">
                  <span v-if="emailConfirmed" @click="editEmail" class=" email-placeholder active" key="email-placeholder">
                      <span class="font-bold">Correo:</span> {{ email }}
                  </span>
              </transition>
              <transition name="slide-fade">
                  <div v-if="showPasswordInput" class="password" :class="{'pass-active': passActive}" key="password-input">
                      <span class="">Contraseña</span>                      
                      <input v-model="password" type="password" placeholder="" @keyup.enter="handleLogin">
                      <button type="button" @click="handleLogin">Iniciar Sesión</button>
                  </div>
              </transition>
          </form>

          <!-- <p class="terminos">Al continuar aceptas las <a href="/condiciones-de-uso">Condiciones de Uso</a> y el <a href="/aviso-de-privacidad">Aviso de privacidad</a>.</p> -->
          <p class="pass-olvidado"><a  @click="navegarARestablecerAcceso">¿Olvidaste la contraseña?</a> <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_166_1460)">
                      <path d="M10.083 6.41671H11.9163V8.25004H10.083V6.41671ZM10.083 10.0834H11.9163V15.5834H10.083V10.0834ZM10.9997 1.83337C5.93967 1.83337 1.83301 5.94004 1.83301 11C1.83301 16.06 5.93967 20.1667 10.9997 20.1667C16.0597 20.1667 20.1663 16.06 20.1663 11C20.1663 5.94004 16.0597 1.83337 10.9997 1.83337ZM10.9997 18.3334C6.95717 18.3334 3.66634 15.0425 3.66634 11C3.66634 6.95754 6.95717 3.66671 10.9997 3.66671C15.0422 3.66671 18.333 6.95754 18.333 11C18.333 15.0425 15.0422 18.3334 10.9997 18.3334Z" fill="#007DAB"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_166_1460">
                      <rect width="22" height="22" fill="white"/>
                      </clipPath>
                      </defs>
              </svg>
          </p>

      </div>
      <div class="caja-blanca">
          <p class=" font-bold titulo">¿No tienes una cuenta?</p>
          <button  @click="goToRegister">Crear una cuenta</button>
      </div>

  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import axios from 'axios';

export default {
  name: 'UserLogin',
  data() {
    return {
      email: '',
      password: '',
      emailConfirmed: false,
      showPasswordInput: false,
      animating: false,
      passActive: false,
      alreadyLoggingIn: false,
    };
  },
  methods: {
    ...mapActions(['login']),
    handleFormSubmit() {
      if (!this.emailConfirmed) {
        this.startEmailAnimation();
      } else {
        if (!this.alreadyLoggingIn) {
          this.handleLogin();
        }
      }
    },
    startEmailAnimation() {
      if (this.validateEmail(this.email)) {
        this.animating = true;
      } else {
        alert("Por favor, introduce un correo electrónico válido.");
      }
    },
    validateEmail(email) {
    // Versión simplificada y clara sin escapes innecesarios
    const re = /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    afterEmailFade() {
      this.emailConfirmed = true;
      this.showPasswordInput = true;
      setTimeout(() => {
        this.passActive = true;
      }, 100);
    },
    afterEmailMoveUp() {
      this.animating = false;
    },
    editEmail() {
      this.emailConfirmed = false;
      this.showPasswordInput = false;
    },
    resetAnimation() {
      this.emailConfirmed = false;
      this.showPasswordInput = false;
    },
    handleLogin() {
      if (this.alreadyLoggingIn) return;
      this.alreadyLoggingIn = true;
      
      axios.post('/api/login_post', {
        email: this.email,
        password: this.password,
      }).then(response => {
        console.log("Login response:", response.data);
        if (response.data.success) {
          console.log("Logging in user:", response.data.user);
          this.login(response.data.user).then(() => {
            console.log("Redirecting to dashboard");
            const redirect = '/dashboard';
            this.$router.push(redirect);
          }).catch(error => {
            console.error("Error after login action:", error);
          });
        } else {
          alert(response.data.error);
        }
      }).catch(error => {
        console.error('Error de inicio de sesión:', error);
        alert('Error en el servidor, intenta de nuevo más tarde.');
      }).finally(() => {
          this.alreadyLoggingIn = false; // Restablecer el estado
        });
    },

    goToRegister() {
      this.$router.push('/registrarse');
    },
    ...mapMutations(['setUser']),
  },
}
</script>


<style lang="sass" scoped>
.contenedor-form-general
    width: 100%
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center
    height: 100%
    .contenedor-form, .caja-blanca
        padding: 20px 15px 15px 15px
        background: #fff
        // border-radius: 20px
        width: 100%
        max-width: 529px
        margin-top: 20px
        .titulo
            font-size: 32px
            text-align: left
            margin-bottom: 20px
            
        form 
            position: relative
            text-align: left
            .correo, .password
                span
                    display: block
                    width: 100%
                    margin: 0
                    padding: 0
                    font-size: 20px
                    font-weight: bold
                    margin: 25px 0 0 0
                input
                    width: 100%
                    border: none
                    padding: 10px 15px
                    // border-radius: 5px
                    background: #fff
                    border-bottom: 2px solid #000
                    transition: all 0.2s
                    margin-bottom: 20px
                    &:focus, &:active                                                
                      outline: none
                      border: none!important
                      border-bottom: 2px solid #999!important
                      
                      
            button
                background: #000
                color: #fff
                margin-top: 15px
                font-size: 0.8em
                text-align: center
            .password
                margin-top: 15px
        button
            display: block
            width: 100%
            border: none            
            padding: 15px
            text-align: left            
            cursor: pointer
            font-size: 16px
        .terminos, .pass-olvidado
            font-size: 0.7em
            max-width: 100%
            margin: 20px auto
            a
                color: #555
                text-decoration: none   
                cursor: pointer
                font-weight: bold
                transition: all 0.2s
                text-decoration: underline
                &:hover
                  color: #000
                  
        .pass-olvidado
            display: flex
            align-items: center
            svg
                transform: scale(0.9)
                margin-left: 5px
        .otros-inicios
            button 
                margin-bottom: 15px                
                display: flex
                align-items: center
                justify-content: center
                svg
                    transform: scale(0.9)
                    margin-right: 10px
            .google
                background: #529BFE
                color: #fff
            .facebook
                background: #184075
                color: #fff
    .caja-blanca
        button
            background: #000
            text-align: center
            color: #fff
/* Animaciones */
// .slide-fade-enter-active, .slide-fade-leave-active
//     transition: all 0.2s ease

// .slide-fade-enter, .slide-fade-leave-to /* Inicio y fin de estado para enter/leave */
//     transform: translateY(10px)
//     opacity: 0

.fade-enter-active, .fade-leave-active
    transition: opacity 0.2s ease
.fade-enter, .fade-leave-to
    opacity: 0
.move-up-enter-active, .move-up-leave-active
    transition: transform 0.2s ease, opacity 0.2s ease
.move-up-enter, .move-up-leave-to
    transform: translateY(-20px)
    opacity: 0
.slide-fade-enter-active, .slide-fade-leave-active
    transition: all 0.2s ease
.slide-fade-enter, .slide-fade-leave-to
    transform: translateY(10px)
    opacity: 0
.email-placeholder
    cursor: pointer
    position: absolute
    line-height: 0
    opacity: 0
    // top: 0
    // display: none
    height: 0
    transition: all 0.2s
.email-placeholder.active
    // display: block
    position: relative
    height: auto
    line-height: 1
    opacity: 1
.password
    opacity: 0
.password.pass-active
    opacity: 1
.logo 
  width: fit-content
  margin: 20px
  padding: 10px
  font-size: 24px 
  font-family: Arial, Helvetica, sans-serif
  font-weight: bold
  text-align: right
  line-height: 0.6
</style>